import { axios } from '@/utils/axios';
import { ErrorResponse } from '@/models/poultry/response';
import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import store from './index';
import { Endpoints } from './endpoints';
import { UpdateProfileParams, UpdatePasswordParams } from './profile.interface';
import { ToastProgrammatic as Toast } from 'buefy';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'client.create',
  store,
})
class ProfileStore extends VuexModule {
  user = null;
  isLoading = false;
  error: ErrorResponse | any = null;

  @Mutation
  setUser(params: any) {
    this.user = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  setLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Action
  async fetchProfile() {
    this.context.commit('setLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      const { data } = await axios.get(`${Endpoints.Profile}/me`);
      this.context.commit('setUser', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async updateProfile(params: UpdateProfileParams) {
    this.context.commit('setLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      const { data } = await axios.put(Endpoints.Profile, params);
      Toast.open({
        type: 'is-success',
        message: 'Profile successfully updated',
      });
      this.context.commit('setUser', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('setLoadingStatus', false);
  }

  @Action
  async updatePassword(params: UpdatePasswordParams) {
    this.context.commit('setLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      const response = await axios.put(`${Endpoints.Users}/password`, params);
      if (response.status === 200 && response.data) {
        this.context.commit('setUser', response.data);
        Toast.open({
          type: 'is-success',
          message: 'Password Successfully Updated!',
        });
      } else {
        Toast.open({
          type: 'is-danger',
          message: 'Password cannot be updated.',
        });
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('setLoadingStatus', false);
  }
}

export default getModule(ProfileStore);
