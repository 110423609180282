






























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import store from '@/store/poultry/profile.store';

@Component({
  components: {
    Modal,
  },
})
export default class ChangePasswordModal extends Vue {
  currentPassword = '';
  newPassword = '';
  confirmPassword = '';

  @Prop({
    type: Boolean,
  })
  private show!: boolean;

  get isDisabled(): boolean {
    const fieldsAreEmpty = !this.currentPassword || !this.newPassword || !this.confirmPassword;
    const passwordsDoNotMatch = this.newPassword.trim().length !== this.confirmPassword.trim().length
    return fieldsAreEmpty || passwordsDoNotMatch || this.newPassword.trim().length < 6;
  }

  @Emit('close')
  handleCloseButtonClicked() {}

  handleFormSubmitted() {
    store.updatePassword({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
    });
  }
}
