

































































import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import store from '@/store/poultry/profile.store';
import ChangePasswordModal from '@/views/poultry/Profile/ChangePasswordModal.vue';
import { ErrorResponse } from '@/models/poultry/response';
import { ToastProgrammatic as Toast } from 'buefy';
import { getMessageFromError } from '@/utils/errors';
import { validateEmail } from '@/utils/validation';

@Component({
  components: {
    Page,
    ChangePasswordModal,
  },
})
export default class Profile extends Vue {
  showModal = false;
  isEmailInvalid = false;
  userProfile = {
    name: '',
    phoneNumber: '',
    email: '',
  };
  error: ErrorResponse | null = null;

  get validationErrors() {
    return getMessageFromError(this.store.error);
  }

  get formIsValid() {
    if (this.userProfile.email) {
      return true;
    }
    return false;
  }

  get store() {
    return store;
  }

  get user(): any {
    return store.user;
  }

  initProfile() {
    if (this.user && this.userProfile) {
      this.userProfile.name = this.user.name;
      this.userProfile.phoneNumber = this.user.phoneNumber;
    }
    if (this.user) {
      this.userProfile.email = this.user.email;
    }
  }

  async created() {
    document.title = 'Elanco | Profile';
    try {
      await store.fetchProfile();
      this.initProfile();
    } catch (error) {
      this.error = error;
    }
  }

  updateProfile() {
    store.updateProfile({
      email: this.userProfile.email,
      fullName: this.userProfile.name,
      phoneNumber: this.userProfile.phoneNumber,
    });
  }

  async handleFormSubmitted() {
    try {
      this.isEmailInvalid = !validateEmail(this.userProfile.email);

      if (this.isEmailInvalid) {
        return;
      }

      await this.updateProfile();
    } catch (error) {
      Toast.open({
        type: 'is-danger',
        message: 'Profile cannot be updated',
      });
    }
  }
}
